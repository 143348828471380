<template>
  <v-main align="center">
    <v-container fluid>
      <v-dialog
        transition="slide-x-transition"
        v-model="dialog"
        hide-overlay
        persistent
        fullscreen
      >
        <v-card
          :color="this.darkmode ? 'darkDrawer' : 'lightDrawer'"
          dark
          class="text-center justify-center"
        >
          <v-container>
            <v-row align="center" justify="center" class="mt-12">
              <v-card-text class="text-uppercase">
                <v-img
                  max-width="100%"
                  width="400px"
                  :src="
                    whiteLabel
                      ? 'so-white-label.png'
                      : 'sensor-online-animated-logo.gif'
                  "
                  style="margin: 0 auto"
                />
                <span
                  class="dark--text display-1 headline font-weight-light mt-4"
                  >LOADING</span
                >
                <span class="dark--text display-1 headline"> DASHBOARD</span>
              </v-card-text>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <grid-layout
        style="
          width: 100% !important;
          min-height: 100% !important;
          margin-bottom: 5rem;
          overflow-x: hidden;
        "
        ref="gridLayout"
        :layout.sync="layout"
        :cols="{ lg: 24, md: 24, sm: 1, xs: 1, xxs: 1 }"
        :col-num="24"
        :col-width="1"
        :row-height="rowHeight"
        :is-draggable="!dragCheck"
        :is-resizeable="!dragCheck"
        :responsive="true"
      >
        <grid-item
          v-for="item in layout"
          :key="item.dashboardModuleId"
          :static="dragCheck"
          :x.sync="item.x"
          :y.sync="item.y"
          :w.sync="item.w"
          :h.sync="item.h"
          :i.sync="item.i"
          :resizeable="true"
          @resize="resizing"
          @resized="resized"
          @moved="moved"
        >
          <Module
            :mode="mode"
            :item="item"
            :key="item.dashboardModuleId"
            :timerTick="timerTick"
            class="pb-10 pb-md-1 pb-sm-1 pa-1"
            v-on:update-message="setMessage"
          />
        </grid-item>
      </grid-layout>

      <ModuleFab
        class="d-none d-sm-none d-md-block"
        v-on:choose-dashboard="chooseDashboard"
        v-bind:mode="mode"
        v-on:mode-change="modeChange"
        v-on:create-done="newCreated"
      />

      <SOMessage v-if="message != ''" :message="message" />
    </v-container>
    <v-snackbar v-model="sizeTooltip.active" color="#2196f3" right>
      {{ $t("common.height") + ": " + sizeTooltip.ySize }}
      | {{ $t("common.width") + ": " + sizeTooltip.xSize }}
    </v-snackbar>
  </v-main>
</template>

<script>
import Module from "@/components/common/Module";
import ModuleFab from "@/components/common/ModuleFab";
import { mapActions, mapState } from "vuex";
import Enum from "@/_helpers/Enum";
import SOMessage from "@/components/common/SoMessage";
import VueGridLayout from "vue-grid-layout";

export default {
  name: "ModuleGrid",

  data() {
    return {
      dialog: true,
      mode: Enum.DashboardMode.DEFAULT,
      updateTimer: "",
      timerTick: 1,
      message: "",
      layout: [],
      dashboardId: 0,
      isMobile: false,
      rowHeight: 30,
      sizeTooltip: {
        active: false,
        xSize: "",
        ySize: "",
      },
    };
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany"]),
    ...mapState("dashboards", [
      "status",
      "currentDashboard",
      "defaultDashboard",
      "hasDefault",
    ]),
    ...mapState("modules", { modules: "modules", moduleStatus: "status" }),
    ...mapState("configuration", ["darkmode", "license"]),

    moduleList: {
      get() {
        return this.modules;
      },

      set(val) {
        this.setModules(val);
        this.updateIndex();
      },
    },

    dragCheck() {
      return this.mode != Enum.DashboardMode.EDIT;
    },
  },

  methods: {
    ...mapActions("dashboards", {
      setDashboard: "setDashboard",
      getDashboard: "getDashboard",
      create: "create",
      update: "update",
      delete: "delete",
    }),

    ...mapActions("modules", {
      getModules: "getModules",
      setModules: "setModules",
      updateIndex: "updateIndex",
      updateModules: "updateModules",
    }),

    async newCreated() {
      await this.getModules({ dashboardId: this.dashboardId });
      this.setLayout();
    },

    setMessage(val) {
      this.message = val;
    },

    modeChange(val) {
      this.mode = val;
    },

    chooseDashboard() {
      this.$emit("choose-dashboard");
    },

    nextTick() {
      if (this.mode == Enum.DashboardMode.EDIT) {
        this.timerTick = 1;
        return;
      }

      if (this.timerTick < 60) {
        this.timerTick += 1;
      } else {
        this.timerTick = 1;
      }
    },

    moved() {
      // Update the database
      if (this.mode == Enum.DashboardMode.EDIT && !this.isMobile) {
        this.updateModules(this.layout);
      }
    },

    resized() {
      // Update the database position
      // Take the layout list and update the modules
      this.sizeTooltip.active = false;
      if (this.mode == Enum.DashboardMode.EDIT) {
        this.updateModules(this.layout);
      }
    },

    resizing(i, newH, newW) {
      // Show tooltip/snackbar when module is being resized
      this.sizeTooltip.xSize = newW;
      this.sizeTooltip.ySize = newH;
      this.sizeTooltip.active = true;
    },

    setLayout() {
      this.$refs.gridLayout.lastBreakpoint = null;
      this.$refs.gridLayout.layouts = {};
      this.layout = [];
      this.layout = [...this.modules];
      var i = 0;
      for (var j in this.layout) {
        this.layout[j].i = i.toString();
        i++;
      }
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.rowHeight = this.isMobile ? 50 : 30;
    },
  },

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    await this.setModules([]);
    this.dashboardId = this.$route.params.id;
    if (this.dashboardId) {
      await this.getDashboard({ dashboardId: this.dashboardId });
      await this.getModules({ dashboardId: this.dashboardId });
      this.setLayout();
      this.updateTimer = setInterval(this.nextTick, 10000);
    } else {
      this.$router.push("dashboard");
    }
  },

  components: {
    Module,
    ModuleFab,
    SOMessage,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },

  updated() {
    if (this.moduleStatus && this.status) {
      let self = this;
      setTimeout(function () {
        self.dialog = false;
      }, 1000);
    }
  },

  beforeDestroy() {
    clearInterval(this.updateTimer);

    if (typeof window !== "undefined")
      window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
.hide-scrollbar-t {
  overflow-y: scroll;
  scrollbar-width: 0px; /* Firefox */
}
.hide-scrollbar-t::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
</style>
